import React, { lazy } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ErrorBoundary } from 'react-error-boundary';
import { AuthContextProvider } from './context/AuthContext';
import { AppContextProvider } from './context/AppContext';
import { PatientSearchContextProvider } from './context/PatientSearchContext';

import RegisterLayountContext from './layouts/RegisterLayout';

import AuthMiddleware from './middleware/Auth';
import FallbackComponent from './components/FallbackComponent/FallbackComponent';
import SidebarMain from './components/Sidebar/SidebarMain/SidebarMain';
import PersistLogin from './components/PersistentLogin/PersistentLogin';
import HygienistContext from './context/HygienistContext';

const DentistDisplacementLayout = lazy(() => import('./layouts/DisplaceLayout'));
const DentistExaminationLayout = lazy(() => import('./layouts/ExamLayout'));
const DentistTreatmentLayout = lazy(() => import('./layouts/TreatLayout'));
const DentistProtectedRoute = lazy(() => import('./layouts/DentistProtectedRoute'));
const SecretaryProtectedRoute = lazy(() => import('./layouts/SecretaryProtectedRoute'));
const PatientSearchLayout = lazy(() => import('./layouts/PatientSearchLayout'))
const AppointmentBookingPage = lazy(() => import('./layouts/AppointmentLayout'))

const LoginHome = lazy(() => import('./pages/LoginHome/LoginHome'));
const Register = lazy(() => import('./pages/Register/Register'));
const HygienistPatientSearch = lazy(() => import('./pages/HygienistPatientSearch/HygienistPatientSearch'));
const HygienistProtectedRoute = lazy(() => import('./layouts/HygienistProtectedRoute'));

const App = () => {
  return (
    <BrowserRouter>
      <ErrorBoundary
        FallbackComponent={FallbackComponent}
        onReset={() => {console.log('GOTTA RESET')}}
      >
        <AuthContextProvider>
          <AppContextProvider>
            <SidebarMain>
                <Routes>
                  <Route path='/' element={<PersistLogin />}>
                    <Route index exact element={<LoginHome />}/>
                    <Route element={<DentistProtectedRoute />}>
                      <Route element={<RegisterLayountContext />}>
                        <Route path='register' element={<AuthMiddleware />}>
                          <Route index element={<Register />}/>
                        </Route>
                      </Route>
                      <Route path='deplacement' element={<AuthMiddleware />}>
                        <Route index element={<DentistDisplacementLayout />}/>
                      </Route>
                      <Route path='examination/:bill_num?' element={<AuthMiddleware />}>
                        <Route index element={<DentistExaminationLayout />}/>
                      </Route>
                      <Route path='treatment' element={<AuthMiddleware />}>
                        <Route index element={<DentistTreatmentLayout />}/>
                      </Route>
                      <Route path='patient_search' element={<AuthMiddleware />}>
                        <Route index element={<PatientSearchLayout />}/>
                      </Route>
                    </Route>
                    <Route element={<SecretaryProtectedRoute />}>
                      <Route path='form_search' element={<AuthMiddleware />}>
                        <Route index element={<PatientSearchLayout />} />
                      </Route>
                      <Route path='appointment_booking' element={<AuthMiddleware />}>
                        <Route index element={<AppointmentBookingPage />} />
                      </Route>
                    </Route>
                    <Route element={<HygienistProtectedRoute />}>
                      <Route path='hygienist/form_search' element={<AuthMiddleware />}>
                        <Route index element={<HygienistPatientSearch />} />
                      </Route>
                    </Route>
                  </Route>
                  <Route path='*' element={<Navigate to='/' />}></Route>
                </Routes>
            </SidebarMain>
          </AppContextProvider>
        </AuthContextProvider>
        <ToastContainer />
      </ErrorBoundary>
    </BrowserRouter>
  )
};

export default App;
